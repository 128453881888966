export default [
  {
    nameFirst: 'Antoine',
    nameLast: 'Roux',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
  {
    nameFirst: 'Chunlian',
    nameLast: 'Zhang',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
  {
    nameFirst: 'Jonathan',
    nameLast: 'Paw',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
  {
    nameFirst: 'Jose',
    nameLast: 'Zavala-Solorio',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
  {
    nameFirst: 'Twaritha',
    nameLast: 'Vijay',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },  
  {
    nameFirst: 'Ganesh',
    nameLast: 'Kolumam',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
  {
    nameFirst: 'Cynthia',
    nameLast: 'Kenyon',
    email: 'cynthia@calicolabs.com',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
  {
    nameFirst: 'Jacob C.',
    nameLast: 'Kimmel',
    email: 'jacob@calicolabs.com',
    affiliations: [{ organization: 'Calico' }],
    corresponding: true,
  },  
];
