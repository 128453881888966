import React from 'react';
import { Bullets } from '@calico/calico-ui-editorial';
import { LinkExternal } from '@calico/calico-ui-kit';

const BASE_URL = 'https://storage.googleapis.com/calico-website-reprog-storage/data';
const META_DES = "Cell age (`age`) and treatment condition (`sample_treatment`) are available as observation metadata in the `.obs` attribute of the AnnData object."

export default [
  {
    format: 'h5ad',
    name: 'Murine adipogenic cell polycistronic reprogramming',
    size: '12.6 GB',
    src: `${BASE_URL}/adipo_sokm.h5ad`,
    title: 'Adipogenic cell SOKM reprogramming',
    content: (
      <div className="max-line-length">
        <p>Data are formatted following the AnnData conventions.
        Genes expressed in few cells or replicates have been removed from this object, representing the matrix we use for downstream analysis.
        We performed denoising with scVI and stored raw measurements in the `layers` attributes.
        </p>
        <p>{META_DES}</p>
        <p>Includes the following files:</p>
        <Bullets
          bullets={[
            {
              title: <strong>adipo_sokm.h5ad</strong>,
              content: 'Single cell mRNA profiles of transiently reprogrammed adipogenic cells.',
            }
          ]}
          className="grey"
        />
      </div>
    ),
  },
  {
    format: 'h5ad',
    name: 'Murine mesenchymal stem cell polycistronic reprogramming',
    size: '7.6 GB',
    src: `${BASE_URL}/msc_sokm.h5ad`,
    title: 'MSC SOKM reprogramming',
    content: (
      <div className="max-line-length">
        <p>Data are formatted following the AnnData conventions.
        Genes expressed in few cells or replicates have been removed from this object, representing the matrix we use for downstream analysis.
        We performed denoising with scVI and stored raw measurements in the `layers` attributes.
        </p>
        <p>{META_DES}</p>
        <p>Includes the following files:</p>
        <Bullets
          bullets={[
            {
              title: <strong>msc_sokm.h5ad</strong>,
              content: 'Single cell mRNA profiles of transiently reprogrammed MSCs.',
            }
          ]}
          className="grey"
        />
      </div>
    ),
  },
  {
    format: 'h5ad',
    name: 'Murine mesenchymal stem cell Yamanaka Factor screen',
    size: '3.2 GB',
    src: `${BASE_URL}/msc_screen.h5ad`,
    title: 'MSC Yamanaka Factor screening',
    content: (
      <div className="max-line-length">
        <p>Data are formatted following the AnnData conventions.
        Genes expressed in few cells or replicates have been removed from this object, representing the matrix we use for downstream analysis.
        We performed denoising with scVI and stored raw measurements in the `layers` attributes.
        </p>
        <p>{META_DES}</p>
        <p>The unique combination of Yamanaka Factors delivered to each cell as determined by demultiplexing of perturbation barcodes is stored in the `combination` and `combination_short` observation attributes.</p>
        <p>Includes the following files:</p>
        <Bullets
          bullets={[
            {
              title: <strong>msc_sokm.h5ad</strong>,
              content: 'Single cell mRNA profiles for MSC pooled screening.',
            }
          ]}
          className="grey"
        />
      </div>
    ),
  },
  {
    format: 'h5ad',
    name: 'Murine myogenic cell Msx1 reprogramming',
    size: '648 MB',
    src: `${BASE_URL}/myo_msx1.h5ad`,
    title: 'Myogenic cell Msx1 reprogramming',
    content: (
      <div className="max-line-length">
        <p>Data are formatted following the AnnData conventions.
        Genes expressed in few cells or replicates have been removed from this object, representing the matrix we use for downstream analysis.
        We performed denoising with scVI and stored raw measurements in the `layers` attributes.
        </p>
        <p>{META_DES}</p>
        <p>Includes the following files:</p>
        <Bullets
          bullets={[
            {
              title: <strong>myo_msx1.h5ad</strong>,
              content: 'Single cell mRNA profiles for transiently reprogrammed myogenic cells.',
            }
          ]}
          className="grey"
        />
      </div>
    ),
  },
  {
    format: 'h5ad',
    name: 'Murine adipogenic cell Yamanaka Factor screen',
    size: '4.1 GB',
    src: `${BASE_URL}/adipo_screen.h5ad`,
    title: 'Adipogenic Yamanaka Factor screening',
    content: (
      <div className="max-line-length">
        <p>Data are formatted following the AnnData conventions.
        Genes expressed in few cells or replicates have been removed from this object, representing the matrix we use for downstream analysis.
        We performed denoising with scVI and stored raw measurements in the `layers` attributes.
        </p>
        <p>{META_DES}</p>
        <p>The unique combination of Yamanaka Factors delivered to each cell as determined by demultiplexing of perturbation barcodes is stored in the `combination` and `combination_short` observation attributes.</p>        
        <p>Includes the following files:</p>
        <Bullets
          bullets={[
            {
              title: <strong>adipo_screen.h5ad</strong>,
              content: 'Single cell mRNA profiles for adipogenic pooled screening.',
            }
          ]}
          className="grey"
        />
      </div>
    ),
  },  
];
